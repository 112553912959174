<template>
  <b-container class="p-4">
    <b-card class="text-center">
      <h2>
        Dziękujemy za przesłanie ankiety!
      </h2>

      <img
        src="@/assets/images/logo/ford-logo.png"
        alt="logo"
        style="width: 160px; display: block; margin: 2rem auto 1rem"
      >
    </b-card>
  </b-container>
</template>

<script>
import { BContainer, BCard } from 'bootstrap-vue'

export default {
  components: { BContainer, BCard },
}
</script>

<style>

</style>
